var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{attrs:{"id":"candidate_nav_sidebar"}},[_c('v-app-bar',{staticClass:"navbar",attrs:{"color":"background","app":"","elevation":"0"}},[_c('div',{staticClass:"d-flex nav-cards"},[_c('div',{staticClass:"base-card d-none d-md-flex align-center route-details"},[_c('div',{staticClass:"route-icon d-flex align-center justify-center"},[_c('inline-svg',{attrs:{"src":_vm.active_route_icon}})],1),_c('p',{staticClass:"base-body-text mb-0 ms-4 route-name"},[_vm._v(" "+_vm._s(_vm.display_route_name())+" ")])]),_c('ChangeLanguage'),_c('v-badge',{attrs:{"bordered":"","color":"danger","offset-x":"11.5","offset-y":"19","content":"0","value":"0"}},[_c('div',{staticClass:"base-card d-flex align-center justify-center notifications"},[_c('img',{attrs:{"src":_vm.notification_icon,"alt":"Notification"}})])]),(_vm.user)?_c('v-menu',{attrs:{"nudge-bottom":"80","rounded":"lg","content-class":"elevation-2"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"base-card user-dropdown-menu d-flex align-center"},'div',attrs,false),on),[_c('v-avatar',{staticClass:"profile-avatar",attrs:{"width":"55","height":"55"}},[(_vm.user.avatar_uri)?_c('img',{attrs:{"src":_vm.user.avatar_uri,"alt":_vm.user.first_name}}):_c('span',[_vm._v(" "+_vm._s(_vm.get_name_initials(_vm.user.first_name + " " + _vm.user.last_name))+" ")])]),_c('div',{staticClass:"ms-4 profile-data d-none d-sm-block"},[_c('p',{staticClass:"base-body-text mb-0 ellipse-text"},[_vm._v(" "+_vm._s(_vm._f("capitalize")(_vm.user.first_name))+" "),(_vm.user.last_name)?_c('span',[_vm._v(" "+_vm._s(_vm._f("capitalize")(_vm.user.last_name))+" ")]):_vm._e()]),(
                  _vm.module !== 'recruiter' &&
                  !_vm.auth_loading &&
                  _vm.get_latest_work_history
                )?_c('p',{staticClass:"base-body-text mb-0 ellipse-text"},[_vm._v(" "+_vm._s(_vm.get_latest_work_history.job_title)+" ")]):_vm._e()]),_c('v-btn',{staticClass:"ms-auto",attrs:{"icon":"","color":"primary"}},[_c('inline-svg',{attrs:{"src":_vm.dropdown_icon}})],1)],1)]}}],null,false,3375479417)},[_c('v-list',[(_vm.module !== 'recruiter')?_c('v-list-item',{staticClass:"px-6",attrs:{"link":"","to":"/candidate/profile"}},[_c('v-list-item-title',[_vm._v("View Profile")])],1):_vm._e(),_c('v-list-item',{staticClass:"px-6",on:{"click":_vm.logout_user}},[_c('v-list-item-title',[_vm._v("Logout")])],1)],1)],1):_vm._e()],1)]),_c('v-navigation-drawer',{staticClass:"sidebar",attrs:{"app":"","left":"","bottom":""}},[(_vm.clean_string(_vm.AppPortal) === _vm.clean_string(_vm.Portals.COREHCS))?_c('img',{attrs:{"src":require("@/assets/logos/corehcs.svg"),"alt":"CoreHCS"}}):(_vm.clean_string(_vm.AppPortal) === _vm.clean_string(_vm.Portals.COREHCMS))?_c('v-img',{attrs:{"width":"120px","src":require("@/assets/logos/core42-hcms.svg"),"alt":"Core-HCMS"}}):_c('img',{attrs:{"src":require("@/assets/logos/hcms.svg"),"alt":"HCMS"}}),_c('v-list',{staticClass:"sidebar-links mt-10 px-0",attrs:{"nav":""}},_vm._l((_vm.sidebar_links),function(link,index){return _c('div',{key:index},[(_vm.module === link.module && link.children.length <= 0)?_c('v-list-item',{staticClass:"sidebar-link px-4",attrs:{"active-class":"active","color":"primary","link":"","to":link.link}},[_c('v-list-item-icon',{staticClass:"mr-4 sidebar-icon"},[_c('inline-svg',{attrs:{"src":_vm.$route.path === link.link ? link.active_icon : link.icon,"alt":link.name}})],1),_c('v-list-item-content',{staticClass:"base-body-text"},[_c('v-list-item-title',{staticClass:"sidebar-title"},[_vm._v(" "+_vm._s(link.name)+" ")])],1)],1):(_vm.module === link.module && link.children)?_c('v-list-group',{staticClass:"sidebar-sub-link-list-group",class:{
            active: _vm.$route.matched.some(function (record) { return record.path.startsWith(link.link); }
            )
          },scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c('v-list-item',{staticClass:"sidebar-sub-link ma-0 activator",attrs:{"color":"primary","link":"","to":link.link,"active-class":""}},[_c('v-list-item-icon',{staticClass:"mr-4 sidebar-icon"},[_c('inline-svg',{attrs:{"src":_vm.$route.matched.some(function (record) { return record.path.startsWith(link.link); }
                    )
                      ? link.active_icon
                      : link.icon,"alt":link.name}})],1),_c('v-list-item-content',{staticClass:"base-body-text"},[_c('v-list-item-title',{staticClass:"sidebar-title"},[_vm._v(" "+_vm._s(link.name)+" ")])],1)],1)]},proxy:true}],null,true)},[_c('div',{staticClass:"sub-links-wrapper ms-6 pr-4 pb-3"},_vm._l((link.children),function(sub_link,index){return _c('router-link',{key:index,staticClass:"\n                sidebar-sub-link\n                text-decoration-none\n                d-flex\n                align-center\n              ",class:{
                'mb-3': link.children.length - 1 !== index
              },attrs:{"exact-active-class":"active","color":"primary","link":"","to":sub_link.link}},[_c('div',{staticClass:"sidebar-sub-link-border"}),_c('v-icon',{staticClass:"sidebar-sub-link-dot",attrs:{"x-small":"","color":"grey"}},[_vm._v("mdi-circle")]),_c('p',{staticClass:"mb-0 sidebar-title base-body-text ms-4"},[_vm._v(" "+_vm._s(sub_link.name)+" ")])],1)}),1)]):_vm._e()],1)}),0)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }