














































































































































































































import Vue from "vue";
import { NavSideBar } from "@/interfaces/candidate/nav_side_bar";
import ChangeLanguage from "@/components/shared/ChangeLanguage.vue";
import { mapActions, mapGetters } from "vuex";
import {
  AUTH_LOADING,
  GET_LATEST_WORK_HISTORY,
  GET_USER_DETAILS,
  LOGOUT_USER
} from "@/store/modules/auth/constants";
import { clean_string, get_name_initials } from "@/utils/global";
import { GET_ROLE_BY_NAME } from "@/store/modules/common/constants";
import { HCMSRoles } from "@/interfaces/data_objects/roles";
import { TranslateResult } from "vue-i18n";
import { IApp } from "@/interfaces";

export default Vue.extend({
  name: "NavSideBar",
  components: { ChangeLanguage },
  data(): NavSideBar {
    return {
      module: "",
      hcms_logo: require("@/assets/logos/hcms.svg"),
      dropdown_icon: require("@/assets/icons/linear/direction-down.svg"),
      notification_icon: require("@/assets/icons/linear/notification.svg"),
      active_route_icon: require("@/assets/icons/linear/speed-test.svg"),
      sidebar_links: [
        {
          name: this.$t("candidate.nav-sidebar.dashboard"),
          link: "/candidate/dashboard",
          icon: require("@/assets/icons/nav_sidebar/dashboard.svg"),
          active_icon: require("@/assets/icons/nav_sidebar/active/dashboard.svg"),
          module: "candidate",
          children: []
        },
        {
          name: this.$t("candidate.nav-sidebar.jobs"),
          link: "/candidate/jobs",
          icon: require("@/assets/icons/nav_sidebar/jobs.svg"),
          active_icon: require("@/assets/icons/nav_sidebar/active/jobs.svg"),
          module: "candidate",
          children: []
        },
        // {
        //   name: this.$t("candidate.nav-sidebar.courses"),
        //   link: "/candidate/courses",
        //   icon: require("@/assets/icons/nav_sidebar/courses.svg"),
        //   active_icon: require("@/assets/icons/nav_sidebar/active/courses.svg"),
        //   module: "candidate",
        //   children: []
        // },
        {
          name: this.$t("recruiter.nav-sidebar.settings"),
          link: "/settings/account",
          icon: require("@/assets/icons/nav_sidebar/settings.svg"),
          active_icon: require("@/assets/icons/nav_sidebar/active/settings.svg"),
          module: "candidate",
          children: [
            {
              name: this.$t("recruiter.nav-sidebar.account"),
              link: "/settings/account",
              icon: require("@/assets/icons/nav_sidebar/settings.svg"),
              active_icon: require("@/assets/icons/nav_sidebar/active/settings.svg"),
              children: []
            }
          ]
        },
        {
          name: this.$t("recruiter.nav-sidebar.dashboard"),
          link: "/recruiter/dashboard",
          icon: require("@/assets/icons/nav_sidebar/dashboard.svg"),
          active_icon: require("@/assets/icons/nav_sidebar/active/dashboard.svg"),
          module: "recruiter",
          children: []
        },
        {
          name: this.$t("recruiter.nav-sidebar.jobs"),
          icon: require("@/assets/icons/nav_sidebar/jobs.svg"),
          active_icon: require("@/assets/icons/nav_sidebar/active/jobs.svg"),
          link: "/recruiter/jobs",
          module: "recruiter",
          children: [
            {
              name: this.$t("recruiter.nav-sidebar.job-listing"),
              link: "/recruiter/jobs"
            },
            {
              name: this.$t("recruiter.nav-sidebar.post-job"),
              link: "/recruiter/jobs/post-job"
            }
          ]
        },
        {
          name: this.$t("recruiter.nav-sidebar.candidates"),
          link: "/recruiter/candidates",
          icon: require("@/assets/icons/nav_sidebar/candidates.svg"),
          active_icon: require("@/assets/icons/nav_sidebar/active/candidates.svg"),
          module: "recruiter",
          children: []
        },
        // {
        //   name: this.$t("recruiter.nav-sidebar.employees"),
        //   link: "/recruiter/employees",
        //   icon: require("@/assets/icons/nav_sidebar/employees.svg"),
        //   active_icon: require("@/assets/icons/nav_sidebar/active/employees.svg"),
        //   module: "recruiter",
        //   children: []
        // },
        {
          name: this.$t("recruiter.nav-sidebar.settings"),
          link: "/settings/account",
          icon: require("@/assets/icons/nav_sidebar/settings.svg"),
          active_icon: require("@/assets/icons/nav_sidebar/active/settings.svg"),
          module: "recruiter",
          children: [
            {
              name: this.$t("recruiter.nav-sidebar.account"),
              link: "/settings/account"
            }
          ]
        },
        {
          name: this.$t("institute.nav-sidebar.dashboard"),
          link: "/institute/dashboard",
          icon: require("@/assets/icons/nav_sidebar/dashboard.svg"),
          active_icon: require("@/assets/icons/nav_sidebar/active/dashboard.svg"),
          module: "institute",
          children: []
        },
        {
          name: this.$t("institute.nav-sidebar.syllabi-comparison"),
          link: "/institute/comparison",
          icon: require("@/assets/icons/nav_sidebar/jobs.svg"),
          active_icon: require("@/assets/icons/nav_sidebar/active/jobs.svg"),
          module: "institute",
          children: []
        },
        {
          name: "Skills Analytics",
          link: "/institute/analysis",
          icon: require("@/assets/icons/nav_sidebar/candidates.svg"),
          active_icon: require("@/assets/icons/nav_sidebar/active/candidates.svg"),
          module: "institute",
          children: []
        },
        // {
        //   name: this.$t("institute.nav-sidebar.faculty"),
        //   link: "/institute/faculty",
        //   icon: require("@/assets/icons/nav_sidebar/employees.svg"),
        //   active_icon: require("@/assets/icons/nav_sidebar/active/employees.svg"),
        //   module: "institute",
        //   children: []
        // },
        {
          name: this.$t("institute.nav-sidebar.settings"),
          link: "/settings/account",
          icon: require("@/assets/icons/nav_sidebar/settings.svg"),
          active_icon: require("@/assets/icons/nav_sidebar/active/settings.svg"),
          module: "institute",
          children: [
            {
              name: this.$t("institute.nav-sidebar.account"),
              link: "/settings/account"
            }
          ]
        }
      ]
    };
  },
  computed: {
    AppPortal(): IApp.Portals {
      return process.env.VUE_APP_PORTAL;
    },
    Portals() {
      return IApp.Portals;
    },
    ...mapGetters("auth", {
      user: GET_USER_DETAILS,
      get_latest_work_history: GET_LATEST_WORK_HISTORY,
      auth_loading: AUTH_LOADING
    }),
    ...mapGetters("common", {
      get_role_by_name: GET_ROLE_BY_NAME
    })
  },
  mounted() {
    if (this.get_role_by_name(HCMSRoles.Recruiter).id === this.user.role_id)
      this.module = "recruiter";
    else if (
      this.get_role_by_name(HCMSRoles.Individual).id === this.user.role_id
    )
      this.module = "candidate";
    else if (
      this.get_role_by_name(HCMSRoles["Educational Institution Representative"])
        .id === this.user.role_id
    )
      this.module = "institute";
  },
  methods: {
    clean_string,
    get_name_initials,
    ...mapActions("auth", {
      logout_user: LOGOUT_USER
    }),
    display_route_name(): string | TranslateResult {
      const route_name = clean_string(this.$route.name ?? "");
      const module = clean_string(this.module);
      // Common route names
      if (
        route_name === "candidate-dashboard" ||
        route_name === "recruiter-dashboard" ||
        route_name === "institute-dashboard"
      ) {
        this.active_route_icon = require("@/assets/icons/nav_sidebar/active/dashboard.svg");
        return "Dashboard";
      }
      if (route_name === "accounts-setting") {
        this.active_route_icon = require("@/assets/icons/nav_sidebar/active/settings.svg");
        return "Settings";
      }
      if (module === "candidate") {
        if (route_name === "candidate-jobs") {
          this.active_route_icon = require("@/assets/icons/nav_sidebar/active/jobs.svg");
          return "Matching Jobs";
        } else if (route_name === "candidate-courses") {
          this.active_route_icon = require("@/assets/icons/nav_sidebar/active/courses.svg");
          return "Courses";
        } else if (
          route_name === "view-profile" ||
          route_name === "personal-profile"
        ) {
          this.active_route_icon = require("@/assets/icons/nav_sidebar/active/candidates.svg");
          return "Profile";
        } else if (
          route_name === "candidate-matching-job" ||
          route_name === "candidate-upskill-career"
        ) {
          this.active_route_icon = require("@/assets/icons/nav_sidebar/active/jobs.svg");
          return "Jobs";
        } else if (route_name === "candidate-error") {
          this.active_route_icon = require("@/assets/icons/nav_sidebar/active/settings.svg");
          return "Error";
        } else {
          this.active_route_icon = require("@/assets/icons/nav_sidebar/active/settings.svg");
          return "Not Found";
        }
      } else if (module === "recruiter") {
        if (
          route_name === "recruiter-jobs" ||
          route_name === "recruiter-job-listing" ||
          route_name === "recruiter-post-job" ||
          route_name === "recruiter-search-standardised-profile" ||
          route_name === "view-job-details"
        ) {
          this.active_route_icon = require("@/assets/icons/nav_sidebar/active/jobs.svg");
          return "Jobs";
        } else if (
          route_name === "recruiter-candidates" ||
          route_name === "recruiter-view-candidates"
        ) {
          this.active_route_icon = require("@/assets/icons/nav_sidebar/active/candidates.svg");
          return "Candidates";
        } else if (route_name === "candidate-profile") {
          this.active_route_icon = require("@/assets/icons/nav_sidebar/active/candidates.svg");
          return "Matching Candidates";
        } else if (
          route_name === "recruiter-employees" ||
          route_name === "recruiter-view-employees"
        ) {
          this.active_route_icon = require("@/assets/icons/nav_sidebar/active/employees.svg");
          return "Employees";
        } else if (route_name === "view-profile") {
          this.active_route_icon = require("@/assets/icons/nav_sidebar/active/candidates.svg");
          return "Profile";
        } else {
          this.active_route_icon = require("@/assets/icons/nav_sidebar/active/settings.svg");
          return "Not Found";
        }
      } else if (module === "institute") {
        if (
          route_name === "syllabi-comparison" ||
          route_name === "select-operation" ||
          route_name === "national-global-comparison" ||
          route_name === "national-job-comparison" ||
          route_name === "national-global-comparison-result" ||
          route_name === "national-job-comparison-result" ||
          route_name === "upskill-courses-national-global" ||
          route_name === "upskill-courses-national-job"
        ) {
          this.active_route_icon = require("@/assets/icons/nav_sidebar/active/jobs.svg");
          return this.$t("institute.nav-sidebar.syllabi-comparison");
        } else if (route_name === "institute-students") {
          this.active_route_icon = require("@/assets/icons/nav_sidebar/active/candidates.svg");
          return this.$t("institute.nav-sidebar.students");
        } else if (route_name === "institute_faculty") {
          this.active_route_icon = require("@/assets/icons/nav_sidebar/active/employees.svg");
          return this.$t("institute.nav-sidebar.faculty");
        } else if (route_name === "analysis-dashboard") {
          return "Skills Analytics";
        } else {
          this.active_route_icon = require("@/assets/icons/nav_sidebar/active/settings.svg");
          return "Not Found";
        }
      } else {
        this.active_route_icon = require("@/assets/icons/nav_sidebar/active/settings.svg");
        return "Not Found";
      }
    }
  }
});
